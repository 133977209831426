import Image from 'next/image'
import { ReactNode, useState } from 'react'
import styled from 'styled-components'
import BlueLines from '/public/assets/icons/BlueLines.svg'
import GreenLines from '/public/assets/icons/GreenLines.svg'

interface GrdiCardPostProps {
  children: ReactNode
  isOdd: boolean
  width?: number
  widthParagraph?: number
  noInvertText?: boolean
  replaceSpan?: JSX.Element
  backgroundColor?: string
  seeMore?: { maxHeight: string }
}

export default function GridCardPost({
  children,
  isOdd,
  width,
  replaceSpan,
  noInvertText,
  widthParagraph,
  seeMore,
  backgroundColor,
}: GrdiCardPostProps) {
  const [seeMoreState, setSeeMoreState] = useState(seeMore ? false : true)

  return (
    <ContainerGrdiCardPost
      isOdd={isOdd}
      width={width}
      haveReplaceSpan={replaceSpan != undefined}
    >
      <div className="secondContent">
        <AlternativeContentResponsiveContent width={width}>
          {replaceSpan}
        </AlternativeContentResponsiveContent>
      </div>
      <SectionPost
        backgroundColor={backgroundColor}
        width={width}
        isLeftSide={isOdd}
        invertText={noInvertText ? false : isOdd}
        widthParagraph={widthParagraph}
      >
        <SeeMoreDiv maxHeight={seeMore?.maxHeight} seeMore={seeMoreState}>
          {children}
        </SeeMoreDiv>
        {seeMore && (
          <SeeMoreButton onClick={() => setSeeMoreState(!seeMoreState)}>
            {seeMoreState ? 'Ler menos' : 'Ler mais'}
          </SeeMoreButton>
        )}
        <BackgroundLine>
          <Image
            src={
              isOdd || backgroundColor === '#4C86EE' ? BlueLines : GreenLines
            }
            alt="Lines"
            layout="fill"
          />
        </BackgroundLine>
      </SectionPost>
    </ContainerGrdiCardPost>
  )
}

const SeeMoreDiv = styled.div<{ maxHeight?: string; seeMore: boolean }>`
  max-height: max-content;
  transition: max-height 1s;
  z-index: 5;
  @media (max-width: 650px) {
    overflow: hidden;
    max-height: ${({ maxHeight, seeMore }) =>
      maxHeight ? (seeMore ? '1300px' : maxHeight) : 'max-content'};
  }
`

const SeeMoreButton = styled.button`
  display: none;
  background: transparent;
  border: none;
  color: ${({ theme }) => theme.colors.white};
  text-decoration: underline;
  font-size: 1.25rem;
  position: relative;
  z-index: 10;
  margin-top: 10px;
  @media (max-width: 650px) {
    display: unset;
  }
`

const ContainerGrdiCardPost = styled.div<{
  isOdd: boolean
  width?: number
  haveReplaceSpan?: boolean
}>`
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
  overflow: hidden;
  flex-direction: ${({ isOdd }) => (!isOdd ? 'row' : 'row-reverse')};
  .secondContent {
    width: ${({ width }) => (width ? 100 - width + '%' : '43.75%')};
  }

  @media (max-width: 650px) {
    flex-direction: column-reverse;
    .secondContent {
      display: none;
    }
  }
`
const AlternativeContentResponsiveContent = styled.div<{ width?: number }>`
  position: absolute;
  width: ${({ width }) => (width ? 100 - width + '%' : '43.75%')};
  height: 102%;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  @media (max-width: 650px) {
    width: 100%;
  }
`

const SectionPost = styled.section<{
  isLeftSide?: boolean
  invertText?: boolean
  widthParagraph?: number
  width?: number
  backgroundColor?: string
}>`
  width: ${({ width }) => (width ? width + '%' : '56.25%')};
  overflow: hidden;
  min-height: 600px;
  background: ${({ isLeftSide, backgroundColor, theme }) =>
    backgroundColor
      ? backgroundColor
      : isLeftSide
      ? theme.colors.blue
      : theme.colors.green};
  display: flex;
  padding: 4rem 6.375rem;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: ${({ invertText }) => (invertText ? 'flex-end' : 'flex-start')};
  text-align: ${({ invertText }) => (invertText ? 'right' : 'left')};
  @media (max-width: 650px) {
    text-align: left;
    align-items: flex-start;
  }
  color: ${({ theme }) => theme.colors.white};

  h3 {
    max-width: 35.3125rem;
    font-style: normal;
    font-weight: 700;
    font-size: 2.5rem;
    line-height: 3rem;
    flex: none;
    order: 0;
    flex-grow: 0;
    z-index: 1;
  }

  a {
    color: #fff;
    cursor: pointer;
  }

  ul {
    color: #fff;
    position: relative;
    z-index: 1;
    margin-top: 1.25rem;
    margin-left: 1.5rem;

    li {
      font-size: 1.125rem;
      line-height: 1.625rem;
      & + li {
        margin-top: 0.75rem;
      }
    }
  }

  .withoutBold {
    font-weight: 500;
  }

  p {
    font-style: normal;
    font-weight: 400;
    font-size: 1.25rem;
    line-height: 1.875rem;
    max-width: ${({ widthParagraph }) =>
      widthParagraph ? widthParagraph + 'px' : '29.6875rem'};
    color: ${({ theme }) => theme.colors.white};
    flex: none;
    margin-top: 1.25rem;
    order: 0;
    z-index: 1;
    flex-grow: 0;
  }

  .paragraphIntroductionAbout {
    margin-top: 1.9375rem;
  }

  .accessTheNotice {
    background: ${({ theme }) => theme.colors.white};
    padding: 0.875rem 1.875rem;
    position: relative;
    z-index: 3;
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.5rem;
    margin-top: 2.125rem;

    display: flex;
    align-items: center;
    border-radius: 6.25rem;
    cursor: pointer;
    color: ${({ theme }) => theme.colors.black};
  }

  .knowMoreLink {
    text-decoration: none;
    font-style: normal;
    display: flex;
    align-items: center;
    cursor: pointer;
    column-gap: 1rem;
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 1.5rem;
    color: ${({ theme }) => theme.colors.white};
    flex: none;
    order: 0;
    background: transparent;
    border: none;
    flex-grow: 0;
    position: absolute;
    z-index: 2;
    bottom: 6.5rem;
    opacity: 0.6;
  }
  @media (max-width: 1000px) {
    padding: 60px 48px;
    h3 {
      font-size: 36px;
      line-height: 40px;
    }
    p {
      max-width: 100%;
      font-size: 18px;
      line-height: 26px;
    }
  }

  @media (max-width: 650px) {
    width: 100%;
    min-height: 496px;
    height: max-content;
    padding: 88px 24px 100px 24px;
  }
`

const BackgroundLine = styled.div`
  position: absolute;
  bottom: -3.125rem;
  left: 0px;
  z-index: 0;
  width: 100%;
  height: 18.125rem;
  @media (max-width: 650px) {
    width: 150%;
    bottom: -70px;
  }
`
