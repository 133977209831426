import type { NextPage } from 'next'
import Head from 'next/head'
import HomeInteractiveContent from '../components/molecules/HomeInteractiveContent'
import HomePosts from '../components/organisms/HomePosts'
import WelcomePortal from '../components/organisms/WelcomePortal'
import FullscreenTemplate from '../template/FullscreenTemplate'

const Home: NextPage = () => {
  return (
    <>
      <Head>
        <title>Portal - Rede Brasileira de Ensino Hibrído</title>
        <meta name="description" content="Generated by create next app" />
        <link rel="icon" href="/favicon.ico" type="image/svg" />
      </Head>
      <FullscreenTemplate>
        <WelcomePortal />
        <HomePosts />
        <HomeInteractiveContent />
      </FullscreenTemplate>
    </>
  )
}

export default Home
