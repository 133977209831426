import Image from 'next/image'
import { useRouter } from 'next/router'
import styled from 'styled-components'
import ArrowSlide from '/public/assets/icons/ArrowSlide.svg'
import WhiteDownloadDocument from '/public/assets/icons/WhiteDownloadDocument.svg'
interface BlueCardSlideProps {
  item: {
    title: string
    text: string
    link: string
  }

  download?: boolean
}

export default function BlueCardSlide({ item, download }: BlueCardSlideProps) {
  const router = useRouter()
  return (
    <ContentBlueCardSlide>
      <div>
        <h3>{item.title}</h3>
        <button
          onClick={() => {
            router.push(item.link)
          }}
        >
          <Image
            src={download ? WhiteDownloadDocument : ArrowSlide}
            alt="Ver conteúdo"
            objectFit="contain"
          />
        </button>
      </div>
      <p>{item.text}</p>
    </ContentBlueCardSlide>
  )
}

const ContentBlueCardSlide = styled.section`
  width: 354px;
  height: 445px;
  padding: 0 30px;
  background: #8cb5ff;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  justify-content: center;
  transition: 0.2s;

  div {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;

    h3 {
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 32px;
      color: ${({ theme }) => theme.colors.white};
      max-width: 208px;
    }

    button {
      background: transparent;
      border: 1px #fff solid;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 44px;
      height: 44px;
      border-radius: 200px;
      transition: 0.2s;
    }
  }

  p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: ${({ theme }) => theme.colors.white};
    position: absolute;
    bottom: 30px;
    width: calc(100% - 60px);
  }

  :hover {
    transition: 0.2s;
    background: ${({ theme }) => theme.colors.blue};
    cursor: pointer;

    button {
      background: #4371c4;
      border: none;
      width: 64px;
      transition: 0.2s;
    }
  }

  @media (max-width: 650px) {
    width: 283.2px;
    height: 356px;
    padding: 0 20px;
    div {
      margin-bottom: 42px;
      h3 {
        font-size: 21px;
        line-height: 28px;
      }
      button {
        min-width: 44px;
      }
    }

    p {
      font-size: 14px;
      line-height: 22px;
      width: calc(100% - 40px);
      bottom: 24px;
    }
  }
`
