import styled from 'styled-components'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination, Autoplay } from 'swiper'
import IllustratorWelcome from '/public/assets/icons/IllustratorWelcome.svg'
import ImpulsionandoEducacao from '/public/assets/img/ImpulsionandoEducacao.png'
import PrimeiroNucleo from '/public/assets/img/primeiro-nucleo.jpeg'
import ExpoRiehImg from '/public/assets/img/Expo-da-rieh.png'
// Import Swiper styles
import 'swiper/css'
import 'swiper/css/pagination'
import Image from 'next/image'
import Link from 'next/link'

export default function WelcomePortal() {
  // return (

  // )
  return (
    <Swiper
      pagination={true}
      modules={[Pagination, Autoplay]}
      autoplay={{
        delay: 5000,
        disableOnInteraction: false,
      }}
    >
      <SwiperSlide>
        <ContainerWelcomePortal>
          <div>
            <h2>
              1ª Expo da RIEH promove troca de experiências e desafios na
              Educação Híbrida
            </h2>
            <br />
            <Link
              href={
                '/1-expo-da-rieh-promove-troca-de-experiencias-e-desafios-na-educacao-hibrida'
              }
              passHref
            >
              <a>Ler mais</a>
            </Link>
          </div>
          <span>
            <Image src={ExpoRiehImg} layout="fill" objectFit="contain" />
          </span>
        </ContainerWelcomePortal>
      </SwiperSlide>
      <SwiperSlide>
        <ContainerWelcomePortal className="inverse ">
          <Link href={'/primeiro-nucleo'} passHref>
            <div style={{ cursor: 'pointer' }}>
              <h2>RIEH entrega laudo final do primeiro núcleo de inovação</h2>
              <p>
                Secretaria de Educação de Goiás conclui etapa de infraestrutura
                e avança para instalação dos equipamentos do Núcleo, visando
                promover a Educação Híbrida no estado
              </p>
            </div>
          </Link>
          <Link href={'/primeiro-nucleo'} passHref>
            <span style={{ cursor: 'pointer' }}>
              <Image src={PrimeiroNucleo} layout="fill" objectFit="cover" />
            </span>
          </Link>
        </ContainerWelcomePortal>
      </SwiperSlide>
      <SwiperSlide>
        <ContainerWelcomePortal>
          <div>
            <h2>Impulsionando a educação híbrida</h2>
            <p className="text-dif">
              Oficina entre estados promove compartilhamento de ideias e
              planejamento
            </p>
            <Link href={'/impulsionando-a-eh'} passHref>
              <a>Ler mais</a>
            </Link>
          </div>
          <span>
            <Image
              src={ImpulsionandoEducacao}
              layout="fill"
              objectFit="contain"
            />
          </span>
        </ContainerWelcomePortal>
      </SwiperSlide>
      <SwiperSlide>
        <ContainerWelcomePortal>
          <div>
            <h2>
              Faça parte da <br /> Rede de Inovação <br />
              para Educação Híbrida
            </h2>
            <p>
              Nosso desafio é promover e implementar estratégias de educação
              híbrida em todos os estados brasileiros, auxiliando na
              implementação do Novo Ensino Médio
            </p>
          </div>
          <span>
            <Image
              src={IllustratorWelcome}
              alt="Bem-vindo"
              layout="fill"
              objectFit="contain"
            />
          </span>
        </ContainerWelcomePortal>
      </SwiperSlide>
    </Swiper>
  )
}

const ContainerWelcomePortal = styled.div`
  width: 100%;
  height: 37.5rem;
  flex-direction: row;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  &.first-innovation-hub {
    column-gap: 24px;

    div {
      p {
        letter-spacing: 2px;
        font-weight: 700;
        color: #18375c;
      }
    }

    h2 {
      letter-spacing: 6px;
    }
    span {
      max-width: 42rem;
    }
  }

  &.inverse {
    flex-direction: row-reverse;
    text-align: right;

    span {
      margin-left: 0rem;
      margin-right: 1.5rem;
    }
    div {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
  }

  div {
    z-index: 1;
    max-width: 484px;

    h2 {
      font-style: normal;
      font-weight: 700;
      font-size: 2.25rem;
      line-height: 3.125rem;
      color: #5290ff;
    }

    p {
      margin-top: 1.25rem;
      font-style: normal;
      font-weight: 500;
      font-size: 1.25rem;
      line-height: 1.75rem;
      color: #92929d;
      max-width: 33.75rem;
      display: flex;
    }
    .text-dif {
      margin-bottom: 30px;
      max-width: 410px;
    }
    a {
      padding: 1rem;
      background-color: #ebf2ff;
      margin-top: 38px;
      text-decoration: none;
      font-family: 'Epilogue';
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
      color: #92929d;
    }
  }
  span {
    position: relative;
    height: 26.5rem;
    max-width: 43.375rem;
    width: 100%;
    margin-left: 1.5rem;
  }

  @media (max-width: 1350px) {
    padding: 0 3.5rem;
  }

  @media (max-width: 1000px) {
    padding: 0 1.5rem;
    height: 600px;
    div {
      max-width: 100%;
      width: 100%;
      h2 {
        font-size: 32px;
        line-height: 42px;
      }
      p {
        font-size: 16px;
        line-height: 24px;
        width: 100%;
      }
    }
  }

  @media (max-width: 650px) {
    padding: 40px 24px;
    flex-direction: column !important;

    div {
      height: max-content;
      text-align: left !important;
      align-items: flex-start !important;
      h2 {
        font-size: 28px;
        line-height: 34px;
      }
      p {
        font-size: 14px;
        line-height: 22px;
        margin-top: 16px;
      }
    }
    span {
      height: 300px;
      margin-left: 0px !important;
      margin-right: 0px !important;
      margin-top: 6px;
    }
  }
`
