import styled from 'styled-components'
import { Swiper, SwiperSlide } from 'swiper/react'
import Image from 'next/image'

import 'swiper/css'

import GreyLines from '/public/assets/icons/GreyLines.svg'
import BlueCardSlide from '../../atoms/BlueCardSlide'

const content = [
  {
    title: 'Ambiente virtual de aprendizagem',
    text: 'Aulas, cursos, oficinas e muito mais: uma plataforma virtual feita sob medida para quem está no Ensino Médio e tem sede de aprender',
    link: 'ava',
  },
  /* {
    title: 'Núcleos de Inovação para Educação Híbrida',
    text: 'Lugares com infraestrutura apropriada para a produção de novos recursos educacionais e onde os profissionais podem se capacitar',
  }, */
  {
    title: 'Observatório da Educação Híbrida',
    text: 'Espaço de coleta e análise de dados e informações sobre a Rede, que monitora e avalia seu funcionamento',
    link: '/observatorio',
  },
  {
    title: 'Repositório da Rede',
    text: 'Uma ferramenta virtual para armazenar, buscar e compartilhar vídeos, textos, áudios, imagens, planos de aula, relatos e muito mais',
    link: '/rede-repositorio',
  },
  {
    title: 'Guia de Implementação da Rede',
    text: 'Uma sugestão de implementação da educação híbrida para as redes públicas de ensino com o apoio da Rede de Inovação para Educação Híbrida e do Ministério da Educação.',
    link: '/files/guia_implementacao.pdf',
  },
]

export default function HomeInteractiveContent() {
  return (
    <ContainerInteractiveContent>
      <header>
        <p>Conteúdo interatívo</p>
        <h2>Mergulhe na Rede</h2>
      </header>

      <Swiper
        className="interactiveContentSlide"
        slidesPerView={'auto'}
        spaceBetween={26}
      >
        {content.map((item, index) => {
          return (
            <SwiperSlide key={index}>
              <BlueCardSlide item={item} />
            </SwiperSlide>
          )
        })}
      </Swiper>
      <span className="background">
        <Image
          src={GreyLines}
          alt="Background"
          layout="fill"
          objectFit="cover"
        />
      </span>
    </ContainerInteractiveContent>
  )
}

const ContainerInteractiveContent = styled.section`
  width: 100%;
  background: #fafafa;
  padding: 7.5rem 0;
  position: relative;

  .background {
    position: absolute;
    top: 0px;
    width: 100vw;
    left: 0px;
    height: 20rem;
  }

  header {
    margin-left: 14.125rem;
    font-style: normal;
    color: ${({ theme }) => theme.colors.black};
    z-index: 1;
    position: relative;

    p {
      font-weight: 500;
      font-size: 1.25rem;
      line-height: 2rem;
      height: 2rem;
      display: flex;
      align-items: center;

      ::before {
        content: '';
        padding: 0.25rem;
        margin-top: -0.375rem;
        margin-right: 0.75rem;
        background: ${({ theme }) => theme.colors.yellow};
      }
    }

    h2 {
      margin-top: 1rem;
      font-weight: 600;
      font-size: 3.5rem;
      line-height: 4rem;
    }
  }

  .interactiveContentSlide {
    margin-top: 3.75rem;
    padding: 0 7.5rem;
    .swiper-slide {
      width: max-content;
    }
  }

  @media (max-width: 1000px) {
    padding: 88px 0;
    header {
      margin-left: 56px;
    }
    .interactiveContentSlide {
      padding: 0 56px;
    }
  }

  @media (max-width: 650px) {
    padding: 64px 0;

    header {
      margin-left: 24px;
      margin-right: 24px;
      h2 {
        margin-top: 8px;
        font-size: 38px;
        line-height: 48px;
      }

      p {
        font-size: 18px;
        line-height: 24px;
      }
    }

    .interactiveContentSlide {
      margin-top: 32px;
      padding: 0 24px;
    }
  }
`
