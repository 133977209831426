import Image from 'next/image'
import styled from 'styled-components'
import KnowMoreHome from '/public/assets/icons/KnowMoreHome.svg'
import GridCardPost from '../../molecules/GridCardPost'
import Link from 'next/link'
import EstaticImg from '/public/assets/img/EstaticImg.png'
import EstaticImg2 from '/public/assets/img/EstaticImg2.png'
import EstaticImg3 from '/public/assets/img/EstaticImg3.png'
import EstaticImg4 from '/public/assets/img/EstaticImg4.png'

const posts = [
  {
    title: 'Conheça a Rede',
    text: 'Gestores, professores e alunos podem se conectar à Rede: plataformas e ferramentas tecnológicas, recursos educacionais, cursos, guias, tutoriais e demais materiais já estão disponíveis',
    link: '/conheca-a-rede',
    image: EstaticImg,
  },
  {
    title: 'Educação Híbrida',
    text: 'A educação híbrida é a nossa aliada para oferecer mais oportunidades de aprendizado aos estudantes do Ensino Médio e garantir uma educação de qualidade em todo o Brasil',
    link: '/educacao-hibrida',
    image: EstaticImg2,
  },
  {
    title: 'Novo Ensino Médio',
    text: 'Com uma organização curricular mais flexível e maior carga horária, o Novo Ensino Médio contempla a oferta de diferentes possibilidades de aprendizagem aos estudantes, os chamados itinerários formativos',
    link: '/novo-ensino-medio',
    image: EstaticImg3,
  },
  {
    title: 'Formação & Capacitação',
    text: 'Nossa Rede busca oferecer formação e capacitação aos educadores a fim de que desenvolvam novas habilidades e descubram como a tecnologia pode auxiliá-los em suas atividades pedagógicas',
    link: '/formacao-e-capacitacao',
    image: EstaticImg4,
  },
]

export default function HomePosts() {
  return (
    <ContainerHomePosts>
      {posts.map((post, index) => {
        const isOdd = index % 2 != 0

        return (
          <>
            {!isOdd && <span />}
            <GridCardPost
              isOdd={isOdd}
              replaceSpan={<Image src={post.image} quality={100} />}
            >
              <h3>{post.title}</h3>
              <p>{post.text}</p>
              {post.title === 'Educação Híbrida' || (
                <Link href={post.link}>
                  <a className="knowMoreLink">
                    Saber mais <Image src={KnowMoreHome} alt="Saiba mais" />
                  </a>
                </Link>
              )}
            </GridCardPost>
            {isOdd && <span />}
          </>
        )
      })}
    </ContainerHomePosts>
  )
}

const ContainerHomePosts = styled.div`
  max-width: 100vw;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
`
